@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    * {
        --color-primary: #white;
        --color-secondary: #F4F5F7; /* Light gray */
        --color-accent: #F5F5F5; /* Lighter Gray */
        --color-accent-2: #E0E0E0; /* Dark Gray */
        --color-darkgray: #6a6a6a;
        --color-orange: #FE9339;
        --color-darkorange: #ED652B;
        --color-lightblue: #009CDA;
        --color-green: #5CAD5C;
        --color-lightgray-100: #e9e9e9; /* Typically used for borders */
        --color-lightgray: #B8B8B8;
        --color-red: #CC0000;
        --color-darkred: #900;
        --color-yellow: #FFBB00;
    }
    html {
        font-family: 'Roboto', sans-serif;
    }
}

h1 {
    @apply text-3xl font-bold;
}
h2 {
    @apply text-2xl font-bold;
}

* {
    margin: 0px;
    padding: 0px;
}

html {
    height: 100%;
}

body {
    height: 100%;
    /*text-align: center;*/
}

h1 {
    margin: 40px 0px 20px 0px;
}

h2, h3 {
    margin: 0px 0px 20px 0px;
}

/*
 * Links
 */
a:link, a:visited, a:hover, a:active {
    color: black;
    text-decoration: none;
}
a.highlight {
    color: #C00;
}

.link:hover {
    /*background-color: blue;*/
}

#root {
    display: flex;
    justify-content: center;
    height: 100%;
}

#container {
    flex-grow: 1;
    flex-shrink: 1;
    height: 100%;
    width: 100%;
}

#container > * {
    height: 100%;
    white-space: nowrap;
    display: flex;
}

.flex-item {
    display: table-cell;
    width: 100%;
    padding-top: 12px;
    padding-bottom: 12px;
    /*padding-left: 10px;*/
}

.flex-po {
    padding: 4px 0px 4px;
    border-bottom: 1px solid #e9e9e9;
}

.dataField {
    flex: 0 0 20px;
    padding-right: 20px;
}

.dataLabel {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    color: #333;
    vertical-align: bottom;
}

.dataLabel.right {
    padding-right: 0px;
    padding-left: 10px;
}

    tr.header {
        /*position: sticky;*/
        top: 0px;
        background-color: white;
    }

    tr.openPO:hover {
        background-color: #E0E0E0;
    }

    th {
        padding: 8px 3px !important;
        border-bottom: 1px solid #e0e0e0;
    }

    td {
        /*padding: 8px 3px;*/
        vertical-align: middle;
        min-width: 70px;
        position: relative;
    }

    td.mini {
        min-width: 10px;
    }

.itemDescription {
    font-size: 14px;
    color: #bfbfbf;
    white-space: normal;
}

.inputDiv {
    border: 1px solid #CBCBCB;
    margin-bottom: 10px;
    position: relative;
    height: 50px;
    display: block;
}

.inputLabel {
    position: absolute;
    bottom: 10px;
    left: 16px;
    color: #8b8b8b;
    transition: bottom 0.25s, font-size 0.25s;
    font-size: 19px;
}

.inputDiv input {
    border: 0px;
    /*border: 1px solid orange;*/
    padding: 6px 10px;
    display: block;
    width: 95%;
    position: absolute;
    bottom: 0px;
    background-color: transparent;
}

select {
    font-size: 14px;
    border: none;
    background-color: white;
}

    .select-container {
        display: inline-flex;
        border: 1px solid #e5e5e5;
        padding: 10px;
    }

.inputDiv.shifted .inputLabel {
    bottom: 25px;
    font-size: 14px;
}

textarea:focus, input:focus{
    outline: none;
}

#loginBox {
    width: 400px;
    padding-top: 30px;
    margin: 0 auto;
    margin-top: 200px;
}

    #loginBox img {
        width: 60%;
    }

    #loginBox form {
        width: 100%;
    }

    #loginBox button {
        width: 100%;
    }

#loginBox img + div {
    font-size: 20px;
    color: #C00;
    margin-bottom: 15px;
    margin-left: 11px;
}

.warningBox {
    background-color: #ffe4e1;
    text-align: center;
    margin-bottom: 5px;
    padding: 12px;
    border: 1px solid #ffb6c1;
    border-radius: 4px;
}

#passwordRequirements ul {
    margin: 0px 18px 16px;
}

label {
    display: block;
}

.sticky {
    position: sticky;
    z-index: 1;
}

#poDetails_labelGeneration, #poDetails_multiline {
    bottom: 0px;
}



/*.table {*/
/*    display: table;*/
/*    border-collapse: collapse;*/
/*}*/

/*    .table-row {*/
/*        display: table-row;*/
/*        width: 100%;*/
/*    }*/

/*    .table-row:not(.header):hover {*/
/*        background-color: #e9e9e9;*/
/*    }*/

/*    .table-row.header {*/
/*        font-weight: bold;*/
/*        border-bottom: 2px solid black;*/
/*    }*/

/*    .table-row:not(.header) {*/
/*        padding: 4px 0px 4px;*/
/*        border-bottom: 1px solid #e9e9e9;*/
/*    }*/

/*        .table-cell {*/
/*            display: table-cell;*/
/*            width: 100%;*/
/*            padding: 12px 10px;*/
/*        }*/

/*        .table-row.header .table-cell {*/
/*            text-transform: uppercase;*/
/*            font-weight: 700;*/
/*            font-size: 12px;*/
/*            color: #333;*/
/*            vertical-align: bottom;*/
/*        }*/

th {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    color: #333;
    vertical-align: bottom;
}

.filterWarning {
    text-align: center;
    color: #a9a9a9;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    vertical-align: bottom;

}

.filterWarning svg {
    font-size: 20px;
    vertical-align: -4px;
    color: #0892D0;
}

#masterLabelControls {
    display: inline-block;
    vertical-align: top;
}

#filteredPOs, #NoOpenPOs {
    text-align: center;
}

.td-link a {
    cursor: pointer;
    display: block;
}

.td-link a, td:not(.td-link) {
    padding: 8px 3px;
}

.lineFlag span, .lineSelect {
    display: block;
    text-align: center;
    font-size: 30px;
}

    .lineFlag > span > svg {    /* FontAwesome icons */
        opacity: .4;
    }

    .lineFlag .fa-check {
        color: green;
    }

    .lineFlag .dissolve .fa-check {
        opacity: 0;
        transition: opacity 3s;
    }

    .lineFlag .fa-times {
        color: red;
    }

.lineSelect {

}

    span.updateStatus {
        font-size: 20px;
        position: absolute;
        left: -22px;
        width: 22px;
        text-align: center;
    }

    span.updateStatus .fa-xmark {
        color: red;
    }

    span.updateStatus .fa-check {
        color: green;
    }

    span.updateStatus .fa-circle-exclamation {
        color: #0892D0;
    }

    span.updateStatus .fa-check.dissolve {
        opacity: 0;
        transition: opacity 2s;
    }

    span.updateStatus .fa-circle-notch {

    }

input[type=date]:focus:enabled {
    border-color: black;
    background-color: white;
}

/*input[type="date"]:not(:focus)::-webkit-inner-spin-button,*/
/*input[type="date"]:not(:focus)::-webkit-calendar-picker-indicator {*/
/*    display: none;*/
/*    -webkit-appearance: none;*/
/*}*/

/** Override the default CSS specifications for the table **/
.react-datepicker {

}

    .react-datepicker-wrapper {
        width: inherit !important;
    }

    .react-datepicker-wrapper input {
        border: 1px solid transparent;
        background-color: transparent;
        font-size: 16px;
        /*border: none;*/
        background-color: inherit;
        max-width: 115px;
    }

    .react-datepicker button {
        min-width: 0px;
    }

    .react-datepicker-popper {
        z-index: 10 !important;
    }

    .react-datepicker__tab-loop {
        display: inline-block;
    }

    .react-datepicker .highlightDate {
        margin-top: .166rem;
        margin-bottom: .166rem;
        margin-left: 0px;
        margin-right: 0px;

        padding-left: .166rem;
        padding-right: .166rem;

        background-color: #A4C3DA;
    }

    /* Borders for calendar window period */
    .react-datepicker .highlightDate.first {
        border-radius: 6px 0px 0px 6px !important;
    }
    .react-datepicker .highlightDate.middle {
    }
    .react-datepicker .highlightDate.last {
        border-radius: 0px 6px 6px 0px !important;
    }
    .react-datepicker .highlightDate.first.last {
        border-radius: 6px 6px 6px 6px !important;
    }

     /* Overriding the default keyboard hover color */
    .react-datepicker .react-datepicker__day:hover {
        background-color: #71A1C5;
        color: black;
    }
    .react-datepicker .first.react-datepicker__day:hover {
        border-radius: 6px 0px 0px 6px !important;
    }
    .react-datepicker .middle.react-datepicker__day:hover {
        border-radius: 0px 0px 0px 0px !important;
    }
    .react-datepicker .last.react-datepicker__day:hover {
        border-radius: 0px 6px 6px 0px !important;
    }
    .react-datepicker .first.last.react-datepicker__day:hover {
        border-radius: 6px 6px 6px 6px !important;
    }

    /** Selected day on calendar **/
    .react-datepicker [aria-selected="true"] {
        border-radius: inherit;
        background-color: #5A819E;
    }

    .react-datepicker .react-datepicker-child {
        white-space: normal;
        text-align: center;
        margin: 2px;
        padding: 2px;
    }

    .badgeCapsule {
        font-size: 12px;
        border-radius: 10px;
        text-align: center;
        padding: 3px 10px 3px 10px;
        color: white;
    }
        span.badgeCapsule {
            display: inline-grid;
        }
        .badgeCapsule.darkorange {
            background-color: #ED652B;
        }
        .badgeCapsule.orange {
            background-color: #FE9339;
        }
        .badgeCapsule.lightblue {
            background-color: #009CDA;
        }
        .badgeCapsule.green {
            background-color: #5CAD5C;
        }
        .badgeCapsule.lightgray {
            background-color: #B8B8B8;
        }

        /** If two badges are on top of each other add a margin between the two. **/
        .badgeCapsule + .badgeCapsule {
            margin-top: 4px;
        }
